<template>
  <div class="shop-order">
    <el-card class="top-card">
      <el-form :model="form" inline>
        <el-form-item :label="$t('gou-mai-ri-qi')" prop="orderTime">
          <el-date-picker
            v-model="form.orderTime"
            type="daterange"
            :start-placeholder="$t('kai-shi-ri-qi')"
            :end-placeholder="$t('jie-shu-ri-qi')"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="search">{{
            $t('cha-xun')
          }}</el-button>
          <el-button @click="reset">{{ $t('zhong-zhi') }}</el-button>
        </el-form-item>
      </el-form>
    </el-card>
    <el-card class="main-card table-page">
      <div class="main-table">
        <div class="table-container">
          <el-table
            :data="tableData"
            border
            @selection-change="handleSelectionChange"
          >
            <el-table-column
              align="center"
              :label="$t('gou-mai-tao-can')"
              prop="orderNumber"
            ></el-table-column>
            <el-table-column
              align="center"
              :label="$t('gou-mai-shi-jian')"
              prop="orderNumber"
            ></el-table-column>
            <el-table-column
              align="center"
              :label="$t('dao-qi-shi-jian')"
              prop="orderNumber"
            ></el-table-column>
            <el-table-column
              align="center"
              :label="$t('fu-kuan-jin-e')"
              prop="orderNumber"
            ></el-table-column>
          </el-table>
        </div>
      </div>
      <div class="bottom-page flex-center">
        <el-pagination
          background
          @size-change="sizeChange"
          @current-change="currentChange"
          :current-page="page.current"
          :page-size="page.size"
          layout="total, sizes, prev, pager, next, jumper"
          :total="page.total"
        >
        </el-pagination>
      </div>
    </el-card>
  </div>
</template>
<script>
import { trainLog } from '@/api/shop'
export default {
  data() {
    return {
      form: {},
      tableData: [],
      selectRows: [],
      page: {
        size: 10,
        current: 1,
        total: 1
      },
      typeList: [
        {
          value: '',
          label: this.$t('quan-bu')
        },
        {
          value: 1,
          label: this.$t('chong-zhi-ding-dan')
        },
        {
          value: 2,
          label: this.$t('ti-xian-ding-dan')
        }
      ],
      detailData: {},
      logisticsList: [],
      showOrderDialog: false,
      showLogisticsDialog: false
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      trainLog({
        current: this.page.current,
        pageSize: this.page.size
      }).then((res) => {
        this.tableData = res.data.Items
        this.page.total = res.data.Pagination.totalRecords
      })
    },
    search() {
      this.page.page = 1
      this.page.size = 10
      this.init
    },
    changeTab(i) {
      this.tabIndex = i
    },
    handleSelectionChange(val) {
      this.selectRows = val
    },
    sizeChange(size) {
      this.page.size = size
      this.init()
    },
    currentChange(page) {
      this.page.page = page
      this.init()
    },
    orderDetail(data) {
      this.detailData = data
      this.showOrderDialog = true
    },
    orderLogistics(data) {
      this.showLogisticsDialog = true
    },
    reset() {
      this.form.orderTime = []
    }
  }
}
</script>